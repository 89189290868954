import React from "react"
// import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import farms from "../../data/farms"
import FarmBlock from "../../components/FarmBlock"

const OurFarmsPage = () => {
  return (
    <Layout>
      <Seo title="Our Partner Farms" />
      <Breadcrumbs title={"Our Partner Farms"} />

      <div className="container py-5">
        <div className="row">
          {farms.map((farm, idx) => {
            return <FarmBlock farm={farm} key={idx} noBorder={idx === 0} />
          })}
        </div>
      </div>
    </Layout>
  )
}
export default OurFarmsPage
